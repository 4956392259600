<template>
  <div>
    <FilterUsersComitati
      @getUtentiList="getResetPage"
      @resetFilters="resetFilters"
    />
    <TableUsersComitati
      @bloccaUtente="$emit('bloccaUtente', $event)"
      @sbloccaUtente="$emit('sbloccaUtente', $event)"
      @resetPsw="$emit('resetPsw', $event)"
      @getUtentiList="getUtentiList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  watch,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/composables/trimInput";
import { useStore } from "vuex";
import FilterUsersComitati from "@/components/components-fit/gestione/utenti/FiltriUsersComitati.vue";
import TableUsersComitati from "@/components/components-fit/gestione/utenti/TableUsersComitati.vue";

export default defineComponent({
  name: "utenti-comitati",
  emits: ["bloccaUtente", "sbloccaUtente", "resetPsw"],
  components: {
    FilterUsersComitati,
    TableUsersComitati,
  },
  props: {
    refresh: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Utenze abilitate");
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const nomeUtenteUtentiComitati = computed(
      () => store.getters.nomeUtenteUtentiComitati
    );
    const denominazioneUtentiComitati = computed(
      () => store.getters.denominazioneUtentiComitati
    );

    const rowsToSkip = computed(() => store.getters.rowsToSkipUtentiComitati);
    const fetchRows = computed(() => store.getters.fetchRowsUtentiComitati);
    const sortColumn = computed(() => store.getters.sortColumnUtentiComitati);
    const sortOrder = computed(() => store.getters.sortOrderUtentiComitati);

    const getUtentiList = () => {
      const localKeys = {
        tipo: "comitati",
        nome_utente: trimInput(nomeUtenteUtentiComitati.value),
        denominazione: trimInput(denominazioneUtentiComitati.value),
        rowstoskip: rowsToSkip.value,
        fetchrows: fetchRows.value,
        sortcolumn: sortColumn.value,
        sortorder: sortOrder.value,
      };
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi.UTENTI_COMITATI_LIST,
        itemName: "utenti_comitati_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersUtentiComitati");
      getUtentiList();
    };
    getUtentiList();

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedutenti_comitati_list")
    );

    const getResetPage = () => {
      if (!loaded.value) return;
      store.commit("resetRowsToSkipUtentiComitati");
      getUtentiList();
    };

    watch(
      () => props.refresh,
      (value) => {
        if (value == true) {
          getUtentiList();
        }
      }
    );

    return {
      getUtentiList,
      resetFilters,
      getResetPage,
    };
  },
});
</script>
