<template>
  <div>
    <div class="card shadow">
      <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="$emit('getUtentiList')">
        <div class="row">
          <div class="col-sm-4 filter">
            <input
              placeholder="Nome utente"
              name="Nome_utente"
              type="text"
              class="form-control"
              @input="setNomeUtente"
              :value="nomeUtente"
            />
          </div>
          <div class="col-sm-4 filter">
            <input
              placeholder="Denominazione"
              name="denominazione"
              type="text"
              class="form-control"
              @input="setDenominazione"
              :value="denominazione"
            />
          </div>
        </div>

        <div class="text-center mt-5">
          <button
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600"
            @click="$emit('getUtentiList')"
            :disabled="!loaded"
          >
            Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
          </button>
          <span
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click.prevent="$emit('resetFilters')"
          >
            <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-date-time-picker/dist/main.css";
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "filtriUtentiComitati",
  emits: ["resetFilters", "getUtentiList"],
  props: {
    resetted: {
      type: Boolean,
    },
  },
  components: {},

  setup(props, { emit }) {
    const store = useStore();

    const nomeUtente = computed(() => store.getters.nomeUtenteUtentiComitati);
    const denominazione = computed(
      () => store.getters.denominazioneUtentiComitati
    );

    const setNomeUtente = (event) => {
      store.commit("setNomeUtenteUtentiComitati", event.target.value);
    };
    const setDenominazione = (event) => {
      store.commit("setDenominazioneUtentiComitati", event.target.value);
    };

    return {
      isActive: false,
      nomeUtente,
      denominazione,
      setNomeUtente,
      setDenominazione,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedutenti_comitati_list")
      ),
    };
  },
});
</script>

<style></style>
